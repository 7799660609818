<template>
  <div class="index">
    <img src="@/assets/bg-4.png" alt="" />
    <div class="box">
      <img src="@/assets/360.png" alt="" @click="$setRead(4, 1)" />
    </div>
    <img class="title-img" src="@/assets/title4-1.png" alt="" />
    <div class="wrap" @click="$setRead(4, 2)">
      <img src="@/assets/4-2/top.png" alt="" />
      <p class="wrap-content">
        温州传统床具的造型发生了一定的变化，以顺应当地日益兴盛的风俗习惯，从形制上来看，可分为架子床和拔步床。
        拔步床内部的空间较为宽敞，大多会依据空间的大小设置椅具、马桶、灯台、小衣橱等家具。瓯外本次展出的传统床具就是拔步床，设计独特、寓意深厚。

        <router-link class="wrap-content-span" to="/type/4_2"
          >查看详情<van-icon name="arrow" style="text-indent: 0em"
        /></router-link>
      </p>
    </div>
    <img class="title-img" src="@/assets/title4-2.png" alt="" />
    <div class="wrap" style="background-color: #f1fffb">
      <swiper ref="swiper" class="swiper-no-swiping" :options="{ loop: true }">
        <swiper-slide v-for="(n, index) in swiperList">
          <div class="ans">
            <div>
              <img :src="$getImgUrl(`assets/game/${index + 1}.png`)" alt="" />
            </div>

            <span @click="show = true">简介</span>
            <p>请从下方选择正确的名称拖动上去</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="drag" @click="$setRead(4, 3)">
      <div v-for="(n, index) in dragList[swiperIndex]">
        <vue-drag-resize
          ref="drag"
          :isResizable="false"
          :isActive="true"
          :minw="getW"
          :w="getW"
          :minh="getH"
          :h="getH"
          :z="999"
          :x="0"
          :y="0"
          @dragging="resize"
          @dragstop="(e) => dragstop(e, index)"
        >
          <img class="drag-img" :src="$getImgUrl(`assets/tip.png`)" alt="" />
          <p class="drag-title">{{ n }}</p>
        </vue-drag-resize>
      </div>
    </div>
    <van-popup v-model="show">
      <p class="content">{{ swiperList[swiperIndex].content }}</p>
    </van-popup>
  </div>
</template>
<script>
let htmlWidth =
  document.documentElement.clientWidth || document.body.clientWidth;
if (htmlWidth > 500) htmlWidth = 500;
export default {
  name: "App",
  components: {},
  data() {
    return {
      swiperList: [
        {
          title: "六角盒",
          content: "放糕点的六角木盒，有时也用来存放豆、芝麻、米、麦等。",
        },
        {
          title: "方斗",
          content: "古代称量粮食的器物，有圆、方形状。",
        },
        {
          title: "方盛",
          content:
            "从前凡遇结婚、做寿等喜庆活动都需要准备糕点、米塑等礼品。为不使其受损，就把它们放在方盛格内，既稳当，又显气魄。",
        },
        {
          title: "凹兜",
          content:
            "圆木舀水用具。用向外弯曲的短木柄做把手，兜圈翻唇，中部内凹，故名凹兜，一般可盛水三五斤。不用时，将其倒扣在水缸盖上，以防腐烂。",
        },
        {
          title: "桃糕印",
          content:
            "从前，过年时家家户户捣年糕，印桃糕是孩子们最喜欢的活动，印一双刻有状元的年糕放在床头压岁，祈盼学业进步、步步高升。桃糕印的图案有人物、鱼等。",
        },
        {
          title: "小箩筐",
          content:
            "俗名田箩儿，竹编农具，盛谷约 50 斤。农村担贩和山区农民用得较多。相比于大箩筐，有使用方便、用途广泛的特点，因此农户家家置办。由于时代不同，生活水平的提高与生产的发展，现在人们都使用塑料筐了。",
        },
        {
          title: "饭桶",
          content:
            "古时盛放多人饭食的小木桶，保暖卫生。现在常用来比喻只会吃不会干的人。",
        },
        {
          title: "铜火箱",
          content:
            "用紫铜或黄铜打制的取暖器，有圆形、长方形，上有盖，造上有多个小气孔，内装水炭或草灰。民间有外女结婚舅舅要送火箱之俗。儿歌:“手倡脚僵，舅舅买火箱。”",
        },
        {
          title: "软囤",
          content:
            "农家囤积谷物的用具，用竹篱编制，宽约 50 厘米，长度根据需要而定。用时展开，打好第一圈后，放进谷物压实。因竹篱防鼠性能差，一般作临时使用。",
        },
      ],
      // 当前游戏进度
      swiperIndex: 0,
      dragList: [
        ["六角盒", "八角盒", "方斗", "方盛"],
        ["六角盒", "八角盒", "方斗", "方盛"],
        ["六角盒", "方斗", "方盛", "八角盒"],
        ["凹兜", "桃糕印", "小箩筐", "饭桶"],
        ["凹兜", "桃糕印", "小箩筐", "饭桶"],
        ["小箩筐", "饭桶", "凹兜", "桃糕印"],
        ["小箩筐", "饭桶", "凹兜", "桃糕印"],
        ["凹兜", "铜火箱", "小箩筐", "饭桶"],
        ["凹兜", "桃糕印", "软囤", "饭桶"],
      ],
      show: false,
    };
  },
  computed: {
    getH() {
      return (htmlWidth / 23.4375) * 2.5;
    },
    getW() {
      return (htmlWidth / 23.4375) * 5.5;
    },
  },
  created() {},
  mounted() {},
  methods: {
    resize(newRect) {
      // console.log(newRect)
    },
    dragstop({ top }, index) {
      this.$setRead(4, 3);
      const { swiperList, swiperIndex, dragList } = this;

      if (top < -80 && top > -300) {
        console.log("进行匹配");
        console.log(dragList[swiperIndex][index]);
        if (dragList[swiperIndex][index] == swiperList[swiperIndex].title) {
          console.log("匹配成功,进入下一题");
          this.$refs.swiper.$swiper.slideNext(1000);
          this.$toast("答对了");
          if (swiperIndex == 8) {
            this.swiperIndex = 0;
          } else {
            this.swiperIndex = swiperIndex + 1;
          }
        } else {
          this.$toast("答错了");
        }
      }
      this.$refs.drag[index].right = 0;
      this.$refs.drag[index].left = 0;
      this.$refs.drag[index].top = 0;
      //
    },
  },
  watch: {},
};
</script>
  
<style lang="less" scoped>
.farm-list {
  top: 20px;
  overflow: hidden;

  > div {
    width: 225px;
    margin: 0;
    overflow: visible;
  }

  .farm {
    width: 210px;
    height: 230px;
    background: #ffffff;
    border-radius: 8px;
    margin: 0 0 0 15px;
    text-align: center;

    .farm-name {
      font-size: 17px;
      font-weight: bold;
      padding: 10px 0 10px;
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }

    .farm-more {
      width: 80px;
      height: 26px;
      background-image: linear-gradient(180deg, #40b178 0%, #1e7943 100%);
      border-radius: 16px;
      line-height: 26px;
      color: #ffffff;
      margin: auto;
      margin-top: 10px;
      font-size: 13px;
    }
  }
}

.drag {
  position: relative;
  height: 40px;
  margin: 0 10px 15px 10px;

  > div {
    width: 25%;
    height: 40px;
    display: inline-block;
    position: relative;
  }

  .drag-img {
    position: relative;
    object-fit: initial;
  }

  .drag-title {
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -60%);
  }
}

.ans {
  position: relative;
  div {
    width: 226px;
    height: 226px;
    margin: 12px 0;
    border-radius: 50%;
    border: 1px solid #2baa1c;
    background-color: #ffffff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      width: 192px;
      height: 192px;
      object-fit: contain;
    }
  }
  span {
    position: absolute;
    right: 2px;
    top: -10px;
    display: inline-block;
    padding: 4px 12px;
    background: linear-gradient(#62c9ab, #438a76);
    border-radius: 4px;
    font-size: 15px;
    color: #fff;
  }
  p {
    text-align: center;
    font-size: 14px;
    opacity: 0.6;
    margin: 10px 0;
  }
}

.vdr.active:before {
  outline: 0px;
}
.content {
  padding: 15px;
  line-height: 1.6;

  width: 240px;
}
.wrap-content {
  padding-bottom: 20px;
}
</style>